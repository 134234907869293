import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { ArrowRight } from 'react-feather'

export interface Props {
  arrow?: boolean
  label: string
  file?: string
  target?: string
  URL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
  variant?: Variant
}

export const Button = memo(function Button({
  arrow,
  label = 'Button',
  file,
  target,
  URL,
  onClick,
  className,
  noActions,
  rel,
  variant = 'default',
}: Props) {
  const buttonClassName = `${className ? className : ''}`
  const buttonDOM = (
    <>
      {arrow ? <ArrowRight /> : null}
      {label}
    </>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
          variant={variant}
        >
          {buttonDOM}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            target === 'tel' || target === 'mailto' ? (
              <Anchor
                className={buttonClassName}
                href={`${target}:${URL}`}
                onClick={onClick}
                rel="noopener"
                variant={variant}
              >
                {buttonDOM}
              </Anchor>
            ) : onClick != undefined ? (
              <Static
                className={buttonClassName}
                onClick={onClick}
                variant={variant}
              >
                {buttonDOM}
              </Static>
            ) : noActions ? (
              <Static className={buttonClassName} variant={variant}>
                {buttonDOM}
              </Static>
            ) : (
              <ButtonItem
                to={URL ? URL : '#'}
                className={buttonClassName}
                variant={variant}
              >
                {buttonDOM}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={URL}
              rel="noopener"
              target={target}
              variant={variant}
            >
              {buttonDOM}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const ButtonStyle = css`
  cursor: pointer;
  display: inline-flex;
  background: #54a78b;
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin-top: 3rem;
  padding: 1.3125rem 1.875rem;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    background: #3e8970;
    svg {
      transform: translateX(7px);
    }
  }
  svg {
    height: 1.125rem;
    margin-right: 0.75rem;
    transition: 0.3s ease-out;
  }

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const ButtonSimpleVariant = css`
  cursor: pointer;
  display: inline-block;
  color: ${theme.colors.variants.neutralDark4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin-top: 3rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:after {
    content: '';
    display: block;
    height: 2px;
    background: ${theme.colors.variants.primaryDark1};
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }
  &:hover {
    color: ${theme.colors.variants.primaryDark1};
    &:after {
      left: 100%;
    }
  }
  svg {
    display: none;
  }

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const ButtonCornersStyle = css`
  position: relative;
  display: inline-block;
  min-width: max-content;
  margin-top: 3.125rem;
  padding: 1.1125rem 2.375rem;
  background: transparent;
  border: 2px solid ${theme.colors.variants.neutralDark4};
  color: ${theme.colors.variants.neutralDark4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  z-index: 1;

  svg {
    display: none;
  }

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
    padding: 1.215rem 3.125rem;
    font-size: 12px;
    letter-spacing: 0.05em;
  }

  &:hover {
    color: ${theme.colors.variants.neutralLight4};
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${theme.colors.variants.neutralDark4};
    transition: top 0.2s ease-in-out;
    z-index: -1;
  }

  &:hover::before {
    top: 0;
  }
`

const ButtonCornersInvertedStyle = css`
  position: relative;
  display: inline-block;
  min-width: max-content;
  margin-top: 3.125rem;
  padding: 1.1125rem 2.375rem;
  background: transparent;
  border: 2px solid ${theme.colors.variants.neutralLight4};
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  z-index: 1;

  svg {
    display: none;
  }

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
    padding: 1.215rem 3.125rem;
    font-size: 12px;
    letter-spacing: 0.05em;
  }

  &:hover {
    color: ${theme.colors.variants.neutralDark4};
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${theme.colors.variants.neutralLight4};
    transition: top 0.2s ease-in-out;
    z-index: -1;
  }

  &:hover::before {
    top: 0;
  }
`

const Anchor = styled.a<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return ButtonStyle
      case 'simple':
        return ButtonSimpleVariant
      case 'corners':
        return ButtonCornersStyle
    }
  }}
`

const Static = styled.div<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return ButtonStyle
      case 'simple':
        return ButtonSimpleVariant
      case 'corners':
        return ButtonCornersStyle
      case 'cornersInverted':
        return ButtonCornersInvertedStyle
    }
  }}
`

const ButtonItem = styled(Link)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return ButtonStyle
      case 'simple':
        return ButtonSimpleVariant
      case 'corners':
        return ButtonCornersStyle
      case 'cornersInverted':
        return ButtonCornersInvertedStyle
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'simple' | 'corners' | 'cornersInverted'
